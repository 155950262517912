<template>
  <div>
    <v-container>
      <v-row class="text-center my-5">
        <v-col class="mb-4 mx-auto mt-n4 selectbox" md="8">
          <v-card flat class="card-border">
            <v-card-text>
              <v-row class="mx-0" style="display: flex">
                <v-col
                  class="d-flex"
                  md="12"
                  style="flex-wrap: wrap; justify-content: center"
                >
                  <v-hover
                    v-for="cat in categories"
                    :key="cat.id"
                    class="mr-5 mb-5 child"
                  >
                    <template v-slot="{ hover }">
                      <v-card
                        class="card-color d-flex align-center"
                        min-width="150px"
                        style="height: 150px"
                        @click="
                          selectedCategory = cat.id;
                          getService();
                        "
                        :class="`elevation-${hover ? 16 : 3}`"
                        :color="
                          hover || selectedCategory == cat.id
                            ? 'teal lighten-1'
                            : ''
                        "
                        :style="
                          hover || selectedCategory == cat.id
                            ? 'color: white; transform: scale(1.1);'
                            : ''
                        "
                      >
                        <div style="margin-left: auto; margin-right: auto">
                          <v-icon
                            class="ma-2"
                            x-large
                            dark
                            :color="
                              hover || selectedCategory == cat.id
                                ? 'white'
                                : 'primary'
                            "
                          >
                            {{ cat.icon }}
                          </v-icon>
                          <p style="font-weight: 700">{{ cat.text }}</p>
                        </div>
                      </v-card>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mx-auto mt-n4 selectdefault" md="8">
          <v-card flat class="card-border">
            <v-card-text>
              <v-select
                :items="categories"
                v-model="selectedCategory"
                @change="getService()"
                item-value="id"
                label="Tanlang"
                solo
                rounded
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="selectedCategory == 1" class="text-center">
        <v-col cols="4" class="mx-auto mt-n4">
          <v-autocomplete
            :items="otmlar"
            v-model="otmselect"
            label="Otmni tanlang"
            item-value="value"
            item-text="name"
            solo
            rounded
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" class="mx-auto mt-n4">
          <v-select
            :items="talim_tili"
            v-model="otmlangselect"
            label="Ta`lim tili"
            item-value="value"
            item-text="name"
            hide-details
            solo
            rounded
          ></v-select>
        </v-col>
        <v-col cols="3" class="mx-auto mt-n4">
          <v-select
            :items="talim_turi"
            v-model="otmtypeselect"
            label="Ta`lim turi"
            item-value="value"
            item-text="name"
            hide-details
            solo
            rounded
          ></v-select>
        </v-col>

        <v-btn
          class="mx-auto mt-n1"
          large
          dense
          @click="getOtm()"
          rounded
          color="teal lighten-1"
          style="color: #fff"
          hide-details
          >Izlash</v-btn
        >
      </v-row>
      <v-row v-if="selectedCategory == 2" class="text-center">
        <v-col cols="4" class="mx-auto mt-n4">
          <v-select
            :items="fanlar"
            v-model="birfan"
            label="Birinchi fan"
            item-value="value"
            item-text="name"
            solo
            rounded
          ></v-select>
        </v-col>
        <v-col cols="4" class="mx-auto mt-n4">
          <v-select
            :items="fanlar"
            v-model="ikkifan"
            label="Ikkinchi fan"
            item-value="value"
            item-text="name"
            hide-details
            solo
            rounded
          ></v-select>
        </v-col>
        <v-col cols="3" class="mx-auto mt-n4">
          <v-text-field
            v-model="mybal"
            label="Ballni kiriting"
            hide-details
            solo
            rounded
          ></v-text-field>
        </v-col>
        <v-btn
          class="mx-auto mt-n1"
          large
          dense
          rounded
          hide-details
          color="teal lighten-1"
          style="color: #fff"
          @click="getOtm()"
          >Izlash</v-btn
        >
      </v-row>
      <v-row v-if="selectedCategory == 2 && filterSearch">
        <v-col cols="4" class="mx-auto mt-n4">
          <v-select
            :items="tolov_turi"
            v-model="moneytype"
            label="Shartnoma turi"
            item-value="value"
            item-text="name"
            solo
            rounded
            @change="getFilter()"
          ></v-select>
        </v-col>
        <v-col cols="4" class="mx-auto mt-n4">
          <v-select
            :items="hududlar"
            v-model="otmregionselect"
            label="Hudud"
            item-value="value"
            item-text="name"
            hide-details
            solo
            rounded
            @change="getFilter()"
          ></v-select>
        </v-col>
        <v-col cols="4" class="mx-auto mt-n4">
          <v-select
            :items="talim_turi"
            v-model="otmedutype"
            label="Ta'lim turi"
            item-value="value"
            item-text="name"
            hide-details
            solo
            rounded
            @change="getFilter()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="selectedCategory == 3" class="text-center">
        <v-col cols="12" class="mx-auto mt-n4">
          <v-autocomplete
            v-model="selectedQuestion"
            :items="questions"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            clearable
            label="Savolni kiriting..."
            rounded
            outlined
            item-text="question"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        
      </v-row>
    </v-container>
    <div class="tb-container" v-if="selectedCategory==3">
      <!-- <h3
        style="text-align: center; color: "
        v-if="selectedCategory == 2 && this.otms.length > 0"
      >
        Siz {{ mybal }} ball bilan {{ grantlarsoni }} yo'nalishga Grant asosida,
        {{ kontraktlarsoni }} yo'nalishga kontraktga kirishingiz mumkin.
      </h3> -->
      <v-alert
        prominent
        color="teal"
        icon="mdi-clock-fast"
        border="left"
        type="info"
        v-if="selectedCategory == 3"
      >
        <p class="mt-4" style="font-size: 24px;">
          Ответ: {{ selectedQuestion && selectedQuestion.length > 0 ? questions.find(v=>v.id==selectedQuestion).answer :'' }}
        </p> 
      </v-alert>
    </div>
    <div class="tb-container" v-if="otms.length > 0">
      <!-- <h3
        style="text-align: center; color: "
        v-if="selectedCategory == 2 && this.otms.length > 0"
      >
        Siz {{ mybal }} ball bilan {{ grantlarsoni }} yo'nalishga Grant asosida,
        {{ kontraktlarsoni }} yo'nalishga kontraktga kirishingiz mumkin.
      </h3> -->
      <v-alert
        prominent
        color="teal"
        icon="mdi-clock-fast"
        border="left"
        type="info"
        v-if="selectedCategory == 2 && this.otms.length > 0"
      >
        Siz {{ mybal }} ball bilan {{ grantlarsoni }} yo'nalishga Grant asosida,
        {{ kontraktlarsoni }} yo'nalishga kontraktga kirishingiz mumkin.
      </v-alert>
      <table>
        <thead>
          <tr>
            <th rowspan="2">№</th>
            <th rowspan="2" style="width: 50px">shifr</th>
            <th v-if="selectedCategory == 2" rowspan="2">otm</th>
            <th rowspan="2">nomi</th>
            <th rowspan="2">til</th>
            <th rowspan="2">turi</th>
            <th rowspan="2" v-if="selectedCategory == 2">hudud</th>
            <th colspan="2" style="text-align: center">qabul kvotasi</th>
            <th colspan="2" style="text-align: center">o'tish bali</th>
          </tr>
          <tr>
            <th>grant</th>
            <th>kontrakt</th>
            <th>grant</th>
            <th>kontrakt</th>
          </tr>
        </thead>
        <tbody v-for="(otm, j) in otms" :key="j">
          <tr
            v-for="(i, k) in otm"
            :key="k"
            :class="{
              grant: i.gr_b < mybal && i.gr_b > 56.7 && selectedCategory == 2,
            }"
          >
            <!-- :style="[
              i.gr_b < mybal && i.gr_b > 56.7 && selectedCategory == 2
                ? { background: '#056385', color: '#fff' }
                : selectedCategory == 1
                ? { background: '#fff' }
                : { background: '#5e2424' },
            ]" -->
            <td>{{ k + 1 }}</td>
            <td>{{ i.mvdir }}</td>
            <td v-if="selectedCategory == 2">{{ i.otm }}</td>
            <td>{{ i.nomi }}</td>
            <td>
              {{
                talim_tili.find((o) => o.value == i.lang)
                  ? talim_tili.find((o) => o.value == i.lang).name
                  : ""
              }}
            </td>
            <td>
              {{
                talim_turi.find((o) => o.value == i.type)
                  ? talim_turi.find((o) => o.value == i.type).name
                  : ""
              }}
            </td>
            <td v-if="selectedCategory == 2">
              {{
                hududlar.find((o) => o.value == i.region)
                  ? hududlar.find((o) => o.value == i.region).name
                  : ""
              }}
            </td>
            <td>{{ i.gr_k }}</td>
            <td>{{ i.con_k }}</td>
            <td>{{ i.gr_b }}</td>
            <td>{{ i.con_b }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import otmInfo from "@/assets/json/otm.json";
import question from "@/assets/json/sofa.json";
// import { filter } from 'vue/types/umd';
export default {
  name: "HelloWorld",

  data() {
    return {
      showInfoDialog: false,
      filterSearch: false,
      selectedService: null,
      selectedCategory: 1,
      services: [],
      tab: null,
      birfan: 0,
      ikkifan: 0,
      grantlarsoni: '',
      kontraktlarsoni: '',
      otmselect: '',
      otmlangselect: '',
      otmtypeselect: '',
      mybal: '',
      moneytype: '',
      otmregionselect: '',
      otmedutype: '',
      selectedQuestion: "",
      selectedAnswer: "",
      categories: [
        {
          id: 1,
          icon: "mdi-format-list-bulleted-square",
          text: "O'tish ballari",
          route: "/",
        },
        {
          id: 2,
          icon: "mdi-office-building",
          text: "Institut tanlash",
          route: "/",
        },
        {
          id: 3,
          icon: "mdi-office-building",
          text: "Yorqinbek",
          route: "/",
        },
      ],
      otmlar: [
        {
          name: "Andijon iqtisodiyot va qurilish instituti",
        },
        {
          name: "Andijon davlat universitetining Pedagogika instituti",
        },
        {
          name: "Andijon mashinasozlik instituti",
        },
        {
          name: "Andijon davlat chet tillari instituti",
        },
        {
          name: "Andijon davlat universiteti",
        },
        {
          name: "Andijon qishloq xo'jaligi va agrotexnologiyalar instituti",
        },
        {
          name: "Andijon davlat tibbiyot instituti",
        },
        {
          name: "Buxoro tibbiyot instituti",
        },
        {
          name: "Buxoro davlat universitetining pedagogika instituti",
        },
        {
          name: "Buxoro tabiiy resurslarni boshqarish instituti",
        },
        {
          name: "Buxoro davlat universiteti",
        },
        {
          name: "Buxoro muhandislik-texnologiya instituti",
        },
        {
          name: "Farg'ona politexnika instituti",
        },
        {
          name: "O'zbekiston davlat jismoniy tarbiya va sport universiteti Farg'ona filiali",
        },
        {
          name: "Farg'ona jamoat salomatligi tibbiyot instituti",
        },
        {
          name: "Qo'qon davlat pedagogika instituti",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti Farg'ona filiali",
        },
        {
          name: "Farg'ona davlat universiteti",
        },
        {
          name: "O'zbekiston davlat san'at va madaniyat instituti Farg'ona mintaqaviy filiali",
        },
        {
          name: "Toshkent davlat texnika universiteti Qo'qon filiali",
        },
        {
          name: "Namangan davlat chet tillari instituti",
        },
        {
          name: "Namangan muhandislik-qurilish instituti",
        },
        {
          name: "Namangan muhandislik-texnologiya instituti",
        },
        {
          name: "Namangan davlat universiteti",
        },
        {
          name: "Toshkent davlat yuridik universiteti",
        },
        {
          name: "Toshkent davlat transport universiteti",
        },
        {
          name: "Toshkent to'qimachilik va yengil sanoat instituti",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti",
        },
        {
          name: "Toshkent moliya instituti",
        },
        {
          name: "Toshkent davlat pedagogika universiteti",
        },
        {
          name: "Toshkent davlat stomatologiya instituti",
        },
        {
          name: "O'zbek milliy musiqa san'ati instituti",
        },
        {
          name: "Geologiya fanlari universiteti",
        },
        {
          name: "Jamoat xavfsizligi universiteti",
        },
        {
          name: "Toshkent davlat o'zbek tili va adabiyoti universiteti",
        },
        {
          name: "Jahon iqtisodiyoti va diplomatiya universiteti",
        },
        {
          name: "O'zbekiston davlat san'at va madaniyat instituti",
        },
        {
          name: "O'zbekiston jurnalistika va ommaviy kommunikatsiyalar universiteti",
        },
        {
          name: "Toshkent arxitektura-qurilish instituti",
        },
        {
          name: "Toshkent davlat iqtisodiyot universiteti",
        },
        {
          name: "Davlat soliq qo'mitasi huzuridagi Fiskal instituti",
        },
        {
          name: "Toshkent davlat texnika universiteti",
        },
        {
          name: "Samarqand veterinariya meditsinasi, chorvachilik va biotexnologiyalar universiteti Toshkent filiali",
        },
        {
          name: "Toshkent kimyo-texnologiya instituti",
        },
        {
          name: "Toshkent davlat sharqshunoslik universiteti",
        },
        {
          name: "Toshkent tibbiyot akademiyasi",
        },
        {
          name: "O'zbekiston davlat konservatoriyasi",
        },
        {
          name: "O'zbekiston davlat xoreografiya akademiyasi",
        },
        {
          name: "Milliy rassomlik va dizayn instituti",
        },
        {
          name: "Toshkent irrigatsiya va qishloq xo'jaligini mexanizatsiyalash muhandislari instituti milliy tadqiqotlar universiteti",
        },
        {
          name: "O'zbekiston davlat jahon tillari universiteti",
        },
        {
          name: "Toshkent farmasevtika instituti",
        },
        {
          name: "Milliy estrada san'ati instituti",
        },
        {
          name: "O'zbekiston milliy universiteti",
        },
        {
          name: "Toshkent pediatriya tibbiyot instituti",
        },
        {
          name: "O'zbekiston xalqaro islom akademiyasi",
        },
        {
          name: "Qarshi irrigatsiya va agrotexnologiyalar instituti",
        },
        {
          name: "Qarshi davlat universiteti",
        },
        {
          name: "Qarshi davlat universitetining Pedagogika instituti",
        },
        {
          name: "Qarshi muhandislik-iqtisodiyot instituti",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti Qarshi filiali",
        },
        {
          name: "Toshkent kimyo-texnologiya instituti Shahrisabz filiali",
        },
        {
          name: "Nukus konchilik instituti",
        },
        {
          name: "Qoraqalpog'iston qishloq xo'jaligi va agrotexnologiyalar inshootlar",
        },
        {
          name: "Qoraqalpoq davlat universiteti",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti Nukus filiali",
        },
        {
          name: "O'zbekiston davlat jismoniy tarbiya va sport universiteti Nukus filiali",
        },
        {
          name: "Nukus davlat universiteti Chimboy fakulteti",
        },
        {
          name: "Samarqand veterinariya meditsinasi, chorvachilik va biotexnologiyalar universiteti Nukus filiali",
        },
        {
          name: "O'zbekiston davlat san'at va madaniyat instituti Nukus filiali",
        },
        {
          name: "Toshkent davlat iqtisodiy universiteti To'rtko'l fakulteti",
        },
        {
          name: "Nukus davlat pedagogika instituti",
        },
        {
          name: "O'zbekiston davlat konservatoriyasi Nukus filiali",
        },
        {
          name: "Qoraqalpog'iston tibbiyot instituti",
        },
        {
          name: "Termiz agrotexnologiyalar va innovatsion rivojlanish instituti",
        },
        {
          name: "Termiz davlat universitetining Pedagogika instituti",
        },
        {
          name: "Toshkent davlat texnika universiteti Termiz filiali",
        },
        {
          name: "Toshkent tibbiyot akademiyasi Termiz filiali",
        },
        {
          name: "Denov tadbirkorlik va pedagogika instituti",
        },
        {
          name: "Termiz davlat universiteti",
        },
        {
          name: "O'zbekiston davlat xoreografiya akademiyasi Urganch filiali",
        },
        {
          name: "Toshkent tibbiyot akademiyasi Urganch filiali",
        },
        {
          name: "Urganch davlat universiteti",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti Urganch filiali",
        },
        {
          name: "Guliston davlat universiteti",
        },
        {
          name: "Toshkent kimyo-texnologiya instituti Yangiyer filiali",
        },
        {
          name: "Toshkent viloyati Chirchiq davlat pedagogika instituti",
        },
        {
          name: "Toshkent davlat agrar universiteti",
        },
        {
          name: "O'zbekiston davlat jismoniy tarbiya va sport universiteti",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti Nurafshon filiali",
        },
        {
          name: "Toshkent davlat texnika universiteti Olmaliq filiali",
        },
        {
          name: "Navoiy davlat konchilik va texnologiyalar universiteti",
        },
        {
          name: "Navoiy davlat pedagogika instituti",
        },
        {
          name: "Jizzax davlat pedagogika instituti",
        },
        {
          name: "Jizzax politexnika instituti",
        },
        {
          name: "O'zbekiston Milliy universiteti Jizzax filiali",
        },
        {
          name: "Samarqand veterinariya meditsinasi , chorvachilik va biotexnologiyalar universiteti",
        },
        {
          name: "Toshkent davlat agrar universiteti Samarqand filiali",
        },
        {
          name: "Toshkent davlat iqtisodiyot universiteti Samarqand filiali",
        },
        {
          name: "Toshkent axborot texnologiyalari universiteti Samarqand filiali",
        },
        {
          name: "Samarqand davlat chet tillari instituti Narpay Xorijiy tillar fakulteti",
        },
        {
          name: "Samarqand davlat arxitektura-qurilish instituti",
        },
        {
          name: "Samarqand iqtisodiyot va servis instituti",
        },
        {
          name: "Ipak yo'li turizm va madaniy meros xalqaro universiteti",
        },
        {
          name: "Samarqand davlat universitetining O'zbekiston-Finlyandiya pedagogika instituti",
        },
        {
          name: "Samarqand davlat universiteti Kattaqo'rg'on filiali",
        },
        {
          name: "Samarqand davlat tibbiyot universiteti",
        },
        {
          name: "Samarqand davlat universiteti Urgut filiali",
        },
        {
          name: "Samarqand davlat universiteti",
        },
        {
          name: "Samarqand davlat chet tillar instituti",
        },
        {
          name: "Samarqand davlat universiteti Muhandislari fizikasi instituti",
        },
        {
          name: "Samarqand davlat chet tillar instituti Payariq Xorijiy tillar fakulteti",
        },
        {
          name: "Samarqand davlat universiteti Agrobiotexnologiyalar va oziq-ovqat xavfsizligi instituti",
        },
      ],
      fanlar: [
        {
          name: "Matematika",
          value: 1,
        },
        {
          name: "Fizika",
          value: 2,
        },
        {
          name: "Geografiya",
          value: 3,
        },
        {
          name: "Ona tili",
          value: 4,
        },
        {
          name: "Tarix",
          value: 5,
        },
        {
          name: "Chet tili",
          value: 6,
        },
        {
          name: "Kasbiy imtihon",
          value: 7,
        },
        {
          name: "Kimyo",
          value: 8,
        },
        {
          name: "Biologiya",
          value: 9,
        },
        {
          name: "O`zbek tili",
          value: 10,
        },
        {
          name: "Qirg`iz tili",
          value: 11,
        },
        {
          name: "Qozoq tili",
          value: 12,
        },
        {
          name: "Qoraqalpoq tili",
          value: 13,
        },
        {
          name: "Rus tili",
          value: 14,
        },
        {
          name: "Tojik tili",
          value: 15,
        },
        {
          name: "Turkman tili",
          value: 16,
        },
        {
          name: "Ingliz tili",
          value: 17,
        },
        {
          name: "Fransuz tili",
          value: 18,
        },
        {
          name: "Nemis tili",
          value: 19,
        },
      ],
      talim_tili: [
        {
          name: "O'zbek tili",
          value: 1,
        },
        {
          name: "Rus tili",
          value: 2,
        },
        {
          name: "Qoraqalpoq tili",
          value: 3,
        },
        {
          name: "Qirg'iz tili",
          value: 5,
        },

        {
          name: "Tojik tili",
          value: 6,
        },
        {
          name: "Qozoq tili",
          value: 5,
        },

        {
          name: "Turkman tili",
          value: 7,
        },
      ],
      talim_turi: [
        {
          name: 'Barchasi',
          value: null,
        },
        {
          name: 'Kunduzgi',
          value: 1,
        },
        {
          name: 'Sirtqi',
          value: 2,
        },
        {
          name: 'Kechki',
          value: 3,
        },
        {
          name: 'Masofaviy',
          value: 4,
        },
      ],
      tolov_turi: [
        {
          name: 'Barchasi',
          value: 1,
        },
        {
          name: 'Grant',
          value: 2,
        },
        {
          name: 'Kontrakt',
          value: 3,
        },
      ],
      hududlar: [
        {
          name: 'Barchasi',
          value: null,
        },
        {
          name: 'Buxoro viloyati',
          value: 1,
        },
        {
          name: "Farg'ona viloyati",
          value: 2,
        },
        {
          name: "Namangan viloyati",
          value: 3,
        },
        {
          name: "Qashqadaryo viloyati",
          value: 4,
        },
        {
          name: "Qoraqalpog'iston",
          value: 5,
        },
        {
          name: "Surxondaryo viloyati",
          value: 6,
        },
        {
          name: "Xorazm viloyati",
          value: 7,
        },
        {
          name: "Sirdaryo viloyati",
          value: 8,
        },
        {
          name: "Toshkent viloyati",
          value: 9,
        },
        {
          name: "Navoiy viloyati",
          value: 10,
        },
        {
          name: "Jizzax viloyati",
          value: 11,
        },
        {
          name: "Andijon viloyati",
          value: 12,
        },
        {
          name: "Samarqand viloyati",
          value: 13,
        },
        {
          name: "Toshkent shahri",
          value: 14,
        },
      ],
      otm: otmInfo,
      otms: [],
      filterotms: [],
      questions: question,
    };
  },
  methods: {
    getOtm() {
      this.otms = [];
      this.filterotms = [];
      // console.log(this.selectedCategory);
      if (this.selectedCategory == 1) {
        var kirishballari = this.otm.filter(
          (d) =>
            d.otm === this.otmselect &&
            d.lang === this.otmlangselect &&
            d.type === this.otmtypeselect
        );
        this.otms.push(kirishballari);
      }
      // let otms = this.otm.filter((obj, pos, arr) => {
      //   return arr.map((mapObj) => mapObj.otm).indexOf(obj.otm) == pos;
      // });
      // otms.forEach((e) => {
      //   this.otms.push(e.otm);
      // });
      // console.log(this.otms);
      if (this.selectedCategory == 2) {
        var otms = this.otm.filter(
          (d) => d.birfan === this.birfan && d.ikkifan === this.ikkifan
        );
        var grantlar = otms.filter(
          (o) =>
            (o.gr_b >= 56.7 && o.gr_b <= this.mybal) ||
            (o.con_b >= 56.7 && o.con_b <= this.mybal)
        );
        // var kontrakt = otms.filter(
        //   (o) =>
        //     (o.con_b < this.mybal && o.gr_b > this.mybal && o.con_b >= 56.7) ||
        //     (o.con_b < this.mybal && o.gr_b == 0 && o.con_b >= 56.7)
        // );
        this.grantlarsoni = otms.filter(
          (o) => o.gr_b >= 56.7 && o.gr_b <= this.mybal
        ).length;
        // console.log(grantlar.length);
        this.kontraktlarsoni = grantlar.length - this.grantlarsoni;

        this.otms.push(grantlar);
        this.filterotms.push(grantlar);
        if (Object.keys(this.otms[0]).length > 25) {
          this.filterSearch = true;
        }
        if (this.moneytype || this.otmregionselect || this.otmedutype) {
          this.getFilter();
        }
      }
    },
    getFilter() {
      this.otms = this.filterotms;
      if (this.moneytype == 2) {
        let grantlar = this.otms[0].filter(
          (o) => o.gr_b >= 56.7 && o.gr_b <= this.mybal
        );
        // console.log(grantlar2, this.mybal);

        this.otms = [];
        this.otms.push(grantlar);
      }
      if (this.moneytype == 3) {
        let kontrakt = this.otms[0].filter(
          (o) =>
            (o.con_b < this.mybal && o.gr_b > this.mybal && o.con_b >= 56.7) ||
            (o.con_b < this.mybal && o.gr_b == 0 && o.con_b >= 56.7)
        );
        // console.log(grantlar2, this.mybal);
        this.otms = [];
        this.otms.push(kontrakt);
      }
      if (this.otmregionselect) {
        let hudud = this.otms[0].filter(
          (o) => o.region == this.otmregionselect
        );
        this.otms = [];
        this.otms.push(hudud);
      }
      if (this.otmedutype) {
        let edutype = this.otms[0].filter((o) => o.type == this.otmedutype);
        this.otms = [];
        this.otms.push(edutype);
      }
    },
    getList() {
      this.showInfoDialog = true;
    },
    getService() {
      this.otms = [];
      this.birfan = 0;
      this.ikkifan = 0;
      this.grantlarsoni = '';
      this.kontraktlarsoni = '';
      this.otmselect = '';
      this.otmlangselect = '';
      this.otmtypeselect = '';
      this.mybal = '';
      this.filterSearch = false;
    },
    getItemText(item) {
      return `${item.code}${item.prefix}`;
    },
  },
  mounted() {
    this.services = this.default;
  },
  computed: {
    screenWidth() {
      return window.innerWidth;
    },
    screenHeight() {
      return window.innerHeight;
    },
  },
};
</script>
<style scoped>
.bg-body {
  height: 500px;
  background-color: #2196f3;
}
.active-class {
  background-color: white;
}
.card-border {
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: transparent;
}
.card-color {
  /* background-color: #787d83; */
  opacity: 0.9;
}
.child {
  width: 20%;
  box-sizing: border-box;
}
.selectdefault {
  display: none;
}
.largetext {
  font-size: 48px;
  font-weight: 700;
}
.middletext {
  font-size: 24px;
}
.smalltext {
  font-size: 16px;
}
.tb-container {
  margin: 0 20px;
}
@media only screen and (max-width: 600px) {
  .selectbox {
    display: none;
  }
  .selectdefault {
    display: block;
  }
  .largetext {
    font-size: 24px;
    font-weight: 700;
  }
  .middletext {
    font-size: 12px;
  }
  .v-btn--fab.v-size--x-small {
    height: 16px;
    width: 16px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 15px;
  color: #2196f3;
}

th {
  text-align: left;
  text-transform: uppercase;
  background-color: #ccddeb;
}

thead > th {
  background-color: #000;
}

tbody > tr:hover {
  background-color: #2196f3;
}

tbody > td {
  position: relative;
}
tr:hover > td {
  color: #fff;
}
.grant {
  background: #3baea3;
  color: #fff;
}
.kontrakt {
  background: rgb(250, 178, 178);
  color: #fff;
}
.grant > td {
  color: #fff;
}
tbody > td:hover,
tbody > td:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

</style>
