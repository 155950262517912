<template>
  <v-app style="background-color: #fafafa !important">
    <v-app-bar app color="white" dark>
      <v-spacer></v-spacer>
      <div>
        <v-img
          src="@/assets/sessiya-logo-3.png"
          max-height="300px"
          max-width="160px"
          class="mx-auto"
        ></v-img>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
        color="primary"
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <HomeView />
    </v-main>
  </v-app>
</template>

<script>
import HomeView from './components/HomeView';

export default {
  name: 'App',

  components: {
    HomeView,
  },

  data() {
    return {};
  },
  methods: {},
  mounted() {
    // this.getValyuta();
  },
};
</script>
<style scoped>
.header-info {
  color: #2196f3;
  font-weight: 700;
  font-size: 16px;
}
.header-currency {
  font-weight: 700;
  font-size: 16px;
}
.space-header {
  display: none;
}
@media only screen and (max-width: 600px) {
  .header-info {
    font-size: 6px;
  }
  .space-header {
    display: block;
    flex-grow: 1 !important;
  }
  .tooltip-text {
    font-size: 8px;
  }
}
@media only screen and (max-width: 960px) {
  .header-info {
    font-size: 12px;
  }
  .space-header {
    display: block;
    flex-grow: 1 !important;
  }
  .tooltip-text {
    font-size: 10px;
  }
}
</style>
